<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page>
    <container
      class=""
      ref="container"
    >
      <list-item
        icon="&#xf052;"
        icon-class-name="tc-main"
      >
        <div
          class="label pa-l"
          slot="label"
        >
          <div class="tc-main fs-big">
            {{ order.expressName }}
          </div>
          <div class="tc-g6 desc">
            {{ orderTracking.trackingNumber }}
          </div>
        </div>
      </list-item>
      <div
        v-if="orderTracking.packageInfo"
        class="pa-a bc-w bor-t"
      >
        <div class="fw-bold">
          包裹信息：
        </div>
        <div class="desc">
          {{ orderTracking.packageInfo }}
        </div>
      </div>

      <div>
        <div class="tc-red pa-a">
          以下时间均为北京时间 (东八区)
        </div>
        <TrackingList
          v-if="trackingList.length"
          :data="trackingList"
        />
        <div
          v-else
          class="pa-a ta-c"
        >
          暂无跟踪信息
        </div>
      </div>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import { Buyer } from '@/api';

export default {
  name: 'Tracking',
  mixins: [pageMixin],
  data() {
    return {
      trackingInfo: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    const { orderSn, trackingNumber } = to.params;
    Buyer.Ex.Order.getOrderTrackingInfo({ orderSn, trackingNumber })
      .then(json => {
        const res = json.data;
        next(vm => {
          vm.trackingInfo = res.data;
        });
      })
      .catch(() => next());
  },
  computed: {
    orderSn() {
      return this.$route.params.orderSn;
    },
    trackingNumber() {
      return this.$route.params.trackingNumber;
    },
    order() {
      return this.trackingInfo.order || {};
    },
    orderTracking() {
      return this.trackingInfo.orderTracking || {};
    },
    trackingList() {
      const {
        trackingInfo,
        clearanceTrackingInfo,
        internationalTrackingInfo,
      } = this.orderTracking;
      return this.$utils.Comm.mergeTracking([
        trackingInfo,
        clearanceTrackingInfo,
        internationalTrackingInfo,
      ]);
    },
  },
};
</script>
<style lang="scss">
</style>
