<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <!--0：全部；10：待付款；20：待发货；30：已发货；99：已取消；100：已完成-->
      <order-status :data="order" />

      <div class="order-detail-content">
        <div class="item">
          <DeliveryAddressShow
            v-if="order.shipMethod===1"
            :dataset="order"
          />
          <list
            v-if="order.goodsSendMode === 4"
            class="item"
          >
            <list-item
              label="付款人姓名："
              :extra="order.subscriberName"
            />
            <list-item
              label="付款人身份证号："
              :extra="order.subscriberIdCardNo"
            />
            <list-item
              label="备注："
              :extra="order.buyerMsg || '无'"
            />
          </list>
          <list v-else>
            <list-item
              label="备注："
              :extra="order.buyerMsg || '无'"
            />
          </list>
        </div>
        <div
          v-if="isTracking"
          class="item"
        >
          <TrackingItem
            class="ma-bor-b"
            :data="order"
            :to="`/order/tracking/${order.orderSn}`"
          />
        </div>

        <list
          v-if="orderStatus===99 && (order.cancelDesc||order.cancelOperateTime)"
          class="item"
        >
          <list-item
            v-if="order.cancelDesc"
            label="取消原因："
            :extra="order.cancelDesc"
          />
          <list-item
            v-if="order.cancelOperateTime"
            label="取消时间："
            :extra="order.cancelOperateTime|dateTime"
          />
        </list>

        <div
          v-if="orderStatus===15"
          class="item"
        >
          <JoinUser :data="groupBuyData.groupBuyDetail" />
          <linker
            :to="`/goods/groupBuy/${order.id}`"
            class="btn pa-a radius btn-main fill"
          >
            邀请好友参团
          </linker>
        </div>

        <list class="item">
          <OrderGoodsItem
            v-for="(item,index) in order.goodsList"
            :key="index"
            :data="item"
            :to="`/goods/detail/${item.goodsId}`"
          />
          <list-item label="商品金额: ">
            <div
              class="price extra"
              slot="extra"
            >
              {{ order.goodsAmount | price }}
            </div>
          </list-item>
          <list-item
            v-if="order.goodsPremiumAmount"
            label="物流保价："
          >
            <div
              class="extra price"
              slot="extra"
            >
              {{ order.goodsPremiumAmount | price }}
            </div>
          </list-item>
          <list-item label="商品运费: ">
            <div
              class="price extra"
              slot="extra"
              v-if="order.freight"
            >
              {{ order.freight | price }}
            </div>
            <div
              class="extra tc-g6"
              slot="extra"
              v-else
            >
              免运费
            </div>
          </list-item>
          <list-item
            v-if="order.couponAmount"
            label="优惠券"
          >
            <div
              class="extra tc-price"
              slot="extra"
            >
              - <span class="price">{{ order.couponAmount | price }}</span>
            </div>
          </list-item>
          <list-item
            v-if="order.giftCardAmount"
            label="礼品卡金额"
          >
            <div
              class="extra tc-price"
              slot="extra"
            >
              <span class="price">{{ order.giftCardAmount | price }}</span>
            </div>
          </list-item>
          <list-item
            v-if="order.reduceAmount"
            label="立减"
          >
            <div
              class="extra tc-price"
              slot="extra"
            >
              - <span class="price">{{ order.reduceAmount | price }}</span>
            </div>
          </list-item>
          <list-item label="订单合计: ">
            <div
              class="price extra"
              slot="extra"
            >
              {{ order.payableAmount | price }}
            </div>
          </list-item>
        </list>

        <template v-if="order.shipMethod===2">
          <list class="item">
            <list-item
              label="门店名称："
            >
              <div
                class="extra"
                slot="extra"
              >
                <div>{{ order.pickupStoreName }}</div>
              </div>
            </list-item>
            <list-item
              label="门店地址："
            >
              <div
                class="extra"
                slot="extra"
              >
                <div>{{ order.pickupStoreRegionName }} {{ order.pickupStoreDetailAddress }}</div>
              </div>
            </list-item>
            <list-item
              label="门店联系人姓名："
            >
              <div
                class="extra"
                slot="extra"
              >
                <div>{{ order.pickupContactFullname }}</div>
              </div>
            </list-item>
            <list-item
              label="门店联系人电话："
            >
              <div
                class="extra"
                slot="extra"
              >
                <div>{{ order.pickupContactTel }}</div>
              </div>
            </list-item>
          </list>
        </template>

        <list class="item">
          <!--        <div class="bar-code">
          <svg ref="barCode" />
        </div>-->
          <list-item
            ref="copyRecommend"
            label="订单号："
            :extra="`${order.orderSn} | 复制`"
            :data-clipboard-text="order.orderSn"
          />
          <list-item
            label="下单时间："
            :extra="order.orderTime|dateTime"
          />
        </list>
      </div>
    </container>
    <div
      class="footer padding margin right"
      v-if="isEditAddress || isCanCancel || isCanRefund || orderStatus === 30 || order.paymentMode === 2"
    >
      <OrderEditAddress
        class="btn btn-g6 link bor radius all"
        :data="order"
        v-if="isEditAddress"
      >
        修改地址
      </OrderEditAddress>
      <template v-if="isCanCancel || isCanRefund">
        <div
          v-if="isCanCancel"
          class="btn btn-g6 link bor radius all"
          @click.stop="onCancelOrder"
        >
          取消订单
        </div>
        <refund-popup
          v-if="isCanRefund"
          :order-id="order.id"
          class="btn btn-g6 link bor radius all"
        >
          订单售后
        </refund-popup>
      </template>
      <pay-popup
        class="btn btn-main radius all"
        v-if="isPay"
        :order-ids="order.id"
        :module="1"
      >
        立即支付
      </pay-popup>
      <linker
        class="btn btn-second"
        v-if="order.paymentMode===2"
        :to="`/order/offlineDetail/${order.id}`"
      >
        付款详情
      </linker>
      <div
        v-if="orderStatus === 30"
        @click.stop="onCompleteOrder"
        class="btn btn-green"
      >
        确认收货
      </div>
    </div>
  </Page>
</template>

<script>
// import JsBarcode from 'jsbarcode';
import { Buyer } from '@/api';
import pageMixin from '@/mixins/layout/page';
import orderStatus from './_orderStatus';
import Clipboard from 'clipboard';

export default {
  name: 'OrderDetails',
  mixins: [pageMixin],
  components: { orderStatus },
  data() {
    return {
      order: {},
      groupBuyData: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    const orderId = to.params.id;
    Buyer.Ex.Order.orderDetail({ orderId })
      .then(orderJson => {
        const order = orderJson.data.data;
        if (order.orderStatus === 15) {
          const { groupBuyId } = order;
          Buyer.Ex.GroupBuy.groupBuyDetail({ id: groupBuyId }).then(json => {
            const groupBuyData = json.data.data;
            next(vm => {
              vm.order = order;
              vm.groupBuyData = groupBuyData;
            });
          });
        } else {
          next(vm => {
            vm.order = order;
          });
        }
      })
      .catch(() => {
        console.log(234234);
        next();
      });
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    orderStatus() {
      return +this.order.orderStatus;
    },
    orderSn() {
      return this.order.orderSn || '';
    },
    isCanRefund() {
      return !!this.order.isCanRefund;
    },
    isCanCancel() {
      return !!this.order.isCanCancel;
    },
    isEditAddress() {
      return (
        this.order.shipMethod === 1 &&
        this.orderStatus > 0 &&
        this.orderStatus < 30
      );
    },
    isTracking() {
      return (
        (this.order.orderStatus === 20 ||
          this.order.orderStatus === 30 ||
          this.order.orderStatus === 100) &&
        this.order.shipMethod + '' === '1'
      );
    },
    isPay() {
      const { orderStatus, order, $globalVar } = this;
      return (
        orderStatus === 10 &&
        order.paymentStatus === 50 &&
        ($globalVar.device.isWeiXin || !$globalVar.device.isMobile)
      );
    },
  },
  watch: {
    /*orderSn(val) {
      if (!val) return;
      console.log(val);
      JsBarcode(this.$refs['barCode'], val, {
        // format: 'pharmacode',
        // lineColor: '#0aa',
        width: 5,
        height: 100,
        displayValue: false,
      });
    },*/
  },
  methods: {
    getOrderDetails(id = this.id) {
      this.$api.Buyer.Ex.Order.orderDetail({
        orderId: id,
      }).then(json => {
        const res = json.data;
        this.order = res.data;
      });
    },
    onCompleteOrder() {
      this.$messageBox.confirm('是否要确认该订单', '温馨提示').then(() => {
        this.$api.Buyer.Ex.Order.completeOrder({
          orderId: this.order.id,
        }).then(json => {
          const res = json.data;
          // res.data.payableAmount = 999.99;
          // res.data.orderStatus = 31;
          this.order = res.data;
          this.$messageBox.alert(res.msg, '温馨提示');
        });
      });
    },
    onCancelOrder() {
      this.$messageBox
        .confirm('确定要取消该订单?', '温馨提示', {
          cancelBtnText: '我再想想',
          confirmBtnText: '确定取消',
        })
        .then(() => {
          this.$api.Buyer.Ex.Order.cancelOrder({
            orderId: this.order.id,
          }).then(json => {
            const res = json.data;
            // res.data.payableAmount = 999.99;
            // res.data.orderStatus = 31;
            this.order = res.data;
            this.$messageBox.alert(res.msg, '温馨提示');
          });
        });
    },

    initClipboard() {
      const { copyRecommend } = this.$refs;
      if (!copyRecommend) {
        setTimeout(() => {
          this.initClipboard();
        }, 500);
        return;
      }
      const copySuccess = () => {
        this.$messageBox.tips('复制成功', 3000);
      };
      const copyError = () => {
        this.$messageBox.tips('复制失败', 3000);
      };
      [copyRecommend].forEach(item => {
        if (!item) return;
        const $node = new Clipboard(item.$el || item);
        $node.on('success', copySuccess);
        $node.on('error', copyError);
      });
    },
  },
  mounted() {
    // this.getOrderDetails();
    this.initClipboard();
  },
};
</script>

<style lang="scss">
.bar-code {
  padding: $padding;
  line-height: 0;
  background-color: #fff;
  > svg {
    width: 100%;
    height: auto;
  }
}
.order-detail-content {
  padding: 0 $padding;
  margin-top: -$padding-big * 2;

  > .item {
    background-color: $color-component-bgc;
    border-radius: $padding-small;
    overflow: hidden;

    + .item {
      margin-top: $margin;
    }
  }
}
</style>
