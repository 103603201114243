<!-- Created by henian.xu on 2018/6/1. -->

<template>
  <!--v-if="+data.orderStatus===10"-->
  <div class="order-status">
    <div class="inner">
      <div class="label">
        <!--0：全部；10：待付款；20：待发货；30：已发货；99：已取消；100：已完成-->
        <!--        <i
          class="f-icon fs-big"
          v-if="+data.orderStatus===10"
        >&#xf050;</i>
        <i
          class="f-icon fs-big"
          v-else-if="+data.orderStatus===20"
        >&#xf051;</i>
        <i
          class="f-icon fs-big"
          v-else-if="+data.orderStatus===30"
        >&#xf052;</i>
        <i
          class="f-icon fs-big"
          v-else-if="+data.orderStatus===99"
        >&#xf054;</i>
        <i
          class="f-icon fs-big"
          v-else-if="+data.orderStatus===100"
        >&#xf053;</i>-->
        {{ data.orderStatusName }}
      </div>
      <div class="extra">
        <div
          v-if="countdownStr"
          class="time"
        >
          支付剩余:{{ countdownStr }}
        </div>
        <div class="badge badge-red fs-small">
          {{ data.shipMethod===1?'送':'取' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderStatus',
  data() {
    return {
      countdownId: 0,
      countdownStr: '',
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orderOverTime() {
      return this.data.orderOverTime || 0;
    },
    isPay() {
      const { data, countdownStr, $globalVar } = this;
      return (
        countdownStr &&
        data.orderStatus === 10 &&
        data.paymentStatus === 50 &&
        ($globalVar.device.isWeiXin || !$globalVar.device.isMobile)
      );
    },
  },
  watch: {
    orderOverTime: {
      handler(val) {
        if (!val) return;
        this.setSurplusTime();
      },
      immediate: true,
    },
  },
  methods: {
    getCountdown() {
      const data = this.$utils.Comm.getRemainTime(
        new Date().getTime(),
        this.orderOverTime,
      );
      if (data.d + data.h + data.m + data.s) {
        this.countdownStr = `${+data.d ? `${data.d}天` : ''}${
          +data.h ? `${data.h}:` : ''
        }${+data.m ? `${data.m}:` : '00:'}${+data.s ? `${data.s}` : '00'}`;
      } else {
        this.countdownStr = '';
      }
    },
    setSurplusTime() {
      this.getCountdown();
      this.countdownId = setInterval(() => {
        this.getCountdown();
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.order-status {
  padding-bottom: $padding-big * 2;
  background-color: $color-main;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding-big $padding;
    color: #fff;
    border-top-left-radius: $padding-small;
    border-top-right-radius: $padding-small;
    > .label {
      font-size: 110%;
    }
    > .extra {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      > .time {
        opacity: 0.7;
      }
      > .badge {
        margin-left: $margin-small;
      }
    }
  }
}
</style>
