<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page>
    <container ref="container">
      <div class="ta-c pa-a bc-w ma-b">
        <img
          :src="qrcodeSrc"
          width="60%"
        >
      </div>
      <list class="ma-b">
        <list-item
          label="订单号:"
          :extra="order.orderSn"
        />
        <list-item label="收货信息：">
          <div
            class="extra"
            slot="extra"
          >
            <div>
              {{ order.deliveryConsignee }}
              <Linker
                class="tc-blue"
                :to="`tel://${order.deliveryPhone}`"
              >
                <Icon code="&#xf072;" />{{ order.deliveryPhone }}
              </Linker>
            </div>
            <div>{{ order.deliveryRegionAddress }}</div>
          </div>
        </list-item>
        <list-item
          label="下单时间:"
          :extra="order.orderTime | moment('YYYY-MM-DD HH:mm:ss')"
        />
      </list>

      <template v-if="orderTrackingList && orderTrackingList.length">
        <TrackingItem
          v-for="(item,index) in orderTrackingList"
          :key="index"
          :data="item"
          :to="item.trackingNumber?`/order/trackingDetail/${orderSn}/${item.trackingNumber}`:''"
        />
      </template>
      <div
        v-else
        class="pa-a ta-c tc-g6"
      >
        等待物流公司分配单号
      </div>

      <template v-if="trackingInfo.examineImageList && trackingInfo.examineImageList.length">
        <list class="ma-t">
          <list-item label="出库实拍图" />
        </list>
        <div class="pa-a">
          <ImgList
            :column="2"
            :data="trackingInfo.examineImageList"
          />
        </div>
      </template>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import { Buyer } from '@/api';

export default {
  name: 'Tracking',
  mixins: [pageMixin],
  data() {
    return {
      qrcodeSrc: '',
      trackingInfo: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    const { orderSn } = to.params;
    Buyer.Ex.Order.getOrderTrackingInfo({ orderSn })
      .then(json => {
        const res = json.data;
        next(vm => {
          vm.trackingInfo = res.data;
        });
      })
      .catch(() => next());
  },
  computed: {
    orderSn() {
      return this.$route.params.orderSn;
    },
    order() {
      return this.trackingInfo.order || {};
    },
    orderTrackingList() {
      return (this.trackingInfo.orderTrackingList || []).map(item => {
        const {
          trackingInfo,
          clearanceTrackingInfo,
          internationalTrackingInfo,
        } = item;
        return {
          ...item,
          trackingList: this.$utils.Comm.mergeTracking([
            trackingInfo,
            clearanceTrackingInfo,
            internationalTrackingInfo,
          ]),
        };
      });
    },
  },
  created() {
    this.$utils.Comm.creatQRCode(
      `${this.$globalVar.shopData.siteUrl}?shareUrl=${encodeURIComponent(
        this.$route.fullPath,
      )}`,
    ).then(url => (this.qrcodeSrc = url));
  },
};
</script>
<style lang="scss">
</style>
